import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/AntdStyledComponentsRegistry.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/components/AuthContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Carregando.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContaBancariaProvider"] */ "/app/components/ContaBancariaContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Perifericos.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/TelaDescanso.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TourProvider"] */ "/app/components/Tour.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Tutoriais.tsx");
