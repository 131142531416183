'use client';

import React, { useState, useEffect } from 'react';
export default function TelaDescanso() {
  const [isInactive, setIsInactive] = useState(false);
  const [currentTime, setCurrentTime] = useState<string>('');
  useEffect(() => {
    const updateCurrentTime = () => {
      const now = new Date();
      setCurrentTime(now.toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }));
    };
    updateCurrentTime();
    const interval = setInterval(updateCurrentTime, 1000);
    const handleFocus = () => setIsInactive(false);
    const handleBlur = () => setIsInactive(true);
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);
    return () => {
      clearInterval(interval);
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);
  if (process.env.NODE_ENV === 'development' || !isInactive) return null;
  return <div id="rest-screen" style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#009af8',
    zIndex: 99999,
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0
  }} data-sentry-component="TelaDescanso" data-sentry-source-file="TelaDescanso.tsx">
      <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
        <p style={{
        fontSize: '3rem',
        color: '#fff',
        marginBottom: '20px'
      }}>
          {currentTime}
        </p>
      </div>

      <img src="/assets/solbra-light.svg" alt="Logo" width="150" style={{
      position: 'absolute',
      bottom: '20px'
    }} />

      <style jsx>{`
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>;
}