'use client';

import React, { useState } from 'react';
import { Drawer, Avatar, Button } from 'antd';
import { UserOutlined, CheckCircleOutlined, ClockCircleOutlined, QuestionCircleFilled } from '@ant-design/icons';
const Tutoriais: React.FC = () => {
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const modules = [{
    title: 'Conhecendo a plataforma Gerencie',
    icon: <UserOutlined />,
    completed: true,
    subsections: [{
      title: 'Visão Geral',
      icon: <UserOutlined />,
      completed: true,
      link: 'https://www.youtube.com/watch?v=video1'
    }]
  }];
  const renderItems = (items: any[]) => items.map((item, index) => <a key={index} href={item.link} target="_blank" rel="noopener noreferrer" style={{
    textDecoration: 'none',
    color: 'inherit'
  }}>
        <div style={{
      display: 'flex',
      alignItems: 'center',
      padding: '8px 0',
      borderBottom: '1px solid #f0f0f0'
    }}>
          {item.completed ? <CheckCircleOutlined style={{
        color: '#52c41a',
        marginRight: 8
      }} /> : <ClockCircleOutlined style={{
        color: '#faad14',
        marginRight: 8
      }} />}
          <span style={{
        fontSize: 14
      }}>{item.title}</span>
        </div>
      </a>);
  return <>
      <div onClick={() => setDrawerVisible(true)} className="fixed bottom-[50px] right-4 z-20 flex items-center justify-center w-12 h-12 bg-[#039af8] text-[#FFFFFF] rounded-full cursor-pointer">
        <QuestionCircleFilled className="text-[#FFFFFF] text-2xl" data-sentry-element="QuestionCircleFilled" data-sentry-source-file="Tutoriais.tsx" />
      </div>

      <Drawer placement="left" closable={false} onClose={() => setDrawerVisible(false)} open={isDrawerVisible} width={350} styles={{
      body: {
        padding: 0
      }
    }} data-sentry-element="Drawer" data-sentry-source-file="Tutoriais.tsx">
        <div style={{
        backgroundColor: '#1890ff',
        padding: '24px 16px',
        color: '#fff',
        position: 'relative'
      }}>
          <h1 style={{
          fontSize: 24,
          fontWeight: 'bold',
          marginBottom: 8
        }}>
            Olá, seja bem-vindo!
          </h1>
          <p style={{
          fontSize: 16
        }}>Vamos começar a aprender? 🚀</p>
        </div>

        <div style={{
        padding: '0 16px',
        marginTop: 16
      }}>
          {modules.map((module, index) => <div key={index} style={{
          marginBottom: 16
        }}>
              <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 8
          }}>
                {module.icon && <Avatar icon={module.icon} style={{
              backgroundColor: '#1890ff',
              marginRight: 8
            }} />}
                <span style={{
              fontSize: 16,
              fontWeight: 500
            }}>
                  {module.title}
                </span>
              </div>
              {module.subsections && renderItems(module.subsections)}
            </div>)}
        </div>
      </Drawer>
    </>;
};
export default Tutoriais;