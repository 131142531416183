'use client';

import React, { createContext, useContext, useState } from 'react';
import dynamic from 'next/dynamic';
const Tour = dynamic(() => import('reactour'), {
  ssr: false
});
const TourContext = createContext(null);
export const TourProvider = ({
  children
}) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [steps, setSteps] = useState([]);
  const startTour = newSteps => {
    setSteps(newSteps);
    setIsTourOpen(true);
  };
  const stopTour = () => setIsTourOpen(false);
  return <TourContext.Provider value={{
    startTour,
    stopTour
  }} data-sentry-element="unknown" data-sentry-component="TourProvider" data-sentry-source-file="Tour.tsx">
      {children}
      <Tour
    // @ts-ignore
    steps={steps} isOpen={isTourOpen} onRequestClose={stopTour} accentColor="#1890ff" rounded={5} data-sentry-element="Tour" data-sentry-source-file="Tour.tsx" />
    </TourContext.Provider>;
};
export const useTour = () => useContext(TourContext);