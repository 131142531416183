'use client';

import { message } from 'antd';
import { useEffect } from 'react';
export default function PerifericosComponent({
  children
}) {
  useEffect(() => {
    const disableCopyPaste = (event: ClipboardEvent) => {
      event.preventDefault();
      message.info('Ação não permitida.');
    };
    document.addEventListener('copy', disableCopyPaste);
    document.addEventListener('cut', disableCopyPaste);
    document.addEventListener('paste', disableCopyPaste);
    let PerifericosInstance;
    async function initializePerifericos() {
      const module = await import('../utils/Perifericos');
      PerifericosInstance = module.default;
    }
    initializePerifericos();
    return () => {
      document.removeEventListener('copy', disableCopyPaste);
      document.removeEventListener('cut', disableCopyPaste);
      document.removeEventListener('paste', disableCopyPaste);
    };
  }, []);
  return children;
}