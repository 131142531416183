'use client';

import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { message } from 'antd';
interface ContaBancariaContextData {
  contasBancarias: any[];
  loading: boolean;
  totalItems: number;
  fetchContas: (searchTerm?: string, page?: number) => void;
}
const ContaBancariaContext = createContext<ContaBancariaContextData | undefined>(undefined);
export const ContaBancariaProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const [contasBancarias, setContasBancarias] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const fetchContas = async (searchTerm: string = '', page: number = 1, pageSize: number = 10) => {
    setLoading(true);
    try {
      const response = await axios.get('/api/financeiro/conta_bancaria', {
        params: {
          page,
          pageSize,
          filtro: {
            nome: searchTerm
          }
        }
      });
      setContasBancarias(response.data.data);
      setTotalItems(response.data.total);
    } catch (error) {
      console.error('Erro ao carregar contas bancárias:', error);
      message.error(error.response.data.message || 'Erro ao carregar contas bancárias');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchContas();
  }, []);
  return <ContaBancariaContext.Provider value={{
    contasBancarias,
    loading,
    totalItems,
    fetchContas
  }} data-sentry-element="unknown" data-sentry-component="ContaBancariaProvider" data-sentry-source-file="ContaBancariaContext.tsx">
      {children}
    </ContaBancariaContext.Provider>;
};
export const useContaBancaria = (): ContaBancariaContextData => {
  const context = useContext(ContaBancariaContext);
  if (!context) {
    throw new Error('useContaBancaria deve ser usado dentro de um ContaBancariaProvider');
  }
  return context;
};